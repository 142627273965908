.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.mascot {
    z-index: 1;
    max-width: 300px;
    width: 100%;
    margin-right: 1rem;
    padding-left: 200px;
    animation: opposed-spin 10s linear infinite;
}

.mascotColumn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 50%;
    padding-right: 1rem;
}

.textColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-basis: 50%;
    padding-left: 1rem;
}


.portal {
    max-width: 600px;
    width: 100%;
    position: absolute;
    z-index: 1;
    animation: spin 10s linear infinite;
}

@keyframes opposed-spin {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.star {
    position: absolute;
    z-index: 0;
    background-color: #ffd700;
    border-radius: 50%;
    opacity: 0.7;
    animation: twinkle linear infinite;
}

@keyframes twinkle {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
}

.closeIcon {
    color: #ffd700;
}

.active {
    color: #ffffff;
    text-decoration: underline;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    font-family: 'Roboto', sans-serif;
    background: linear-gradient(135deg, #3c3b63, #1f1a3a);
    padding: 1rem;
    box-sizing: border-box;
}

.realmName {
    color: #ffd700;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}
.titleContainer{
    text-align: center;
    justify-content: center;
}

.realmContainer {
    background-color: #1f1a3a;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 2rem;
    width: 100%;
    box-sizing: border-box;
}

.connectContainer {
    background-color: #1f1a3a;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 2rem;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.terms-and-conditions {
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
    line-height: 1.6;
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    color: #f1f1f1;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: #2a2b3a linear-gradient(135deg, #3c3b63, #1f1a3a);

    box-sizing: border-box;
}

.instructions {
    color: #ffffff;
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.submitAnswerButton {
    background-color: #008cba;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
}

.leaderboardTable {
    border-collapse: collapse;
    color: #f1f1f1;
    margin-bottom: 1rem;
}

.leaderboardTable th,
.leaderboardTable td {
    padding: 0.75rem;
    border: 1px solid #3c3b63;
    text-align: center;
}

.leaderboardTable thead {
    background-color: #1f1a3a;
    font-weight: bold;
}

.leaderboardTable tbody tr:nth-child(odd) {
    background-color: rgba(60, 59, 99, 0.1);
}

.leaderboardTable tbody tr:nth-child(even) {
    background-color: rgba(60, 59, 99, 0.2);
}

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.title {
    z-index: 5;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #ffd700;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.info {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}
.gameContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.gameButton {
    font-size: 150%;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: 0.25rem;
    background-color: #8b60ed;
    color: #ffffff;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    width: 65%;
    height: 65%;
}

.buttonContainer {
    justify-content: center;
}

.button {
    height: 3rem;
    width: 100%;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin: 1rem;
    border: none;
    border-radius: 0.25rem;
    background-color: #8b60ed;
    color: #ffffff;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s;
    text-align: center;
    text-decoration: none;
    display: inline-block;
}

.button:hover,
.button:focus:hover {
    background-color: #1f1a3a;
}

.button:focus {
    outline: none;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.welcomeText {
    z-index: 5;
    flex-grow: 1;
    text-align: center;
}

.welcomeText h1 {
    line-height: 1.6;
    margin-bottom: 1rem;
    color: #ffd700;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.welcomeText h2 {
    line-height: 1.6;
    margin-bottom: 1rem;
    color: #ffd700;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.welcomeText h3 {
    line-height: 1.6;
    margin-bottom: 1rem;
    color: #ffd700;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}


.welcomeText p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
    color: #ffffff;
}

.welcomeText a {
    text-decoration: underline;
    margin-bottom: 1rem;
    color: #ffffff;
}

.quizText {
    z-index: 5;
    flex-grow: 1;
}

.quizText p {
    font-size: 1.5rem;
    line-height: 1.6;
    margin-bottom: 1rem;
    color: #ffffff;
}

.text {
    z-index: 5;
    flex-grow: 1;
}

.text p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
    color: #ffffff;
}

.text a {
    text-decoration: underline;
    margin-bottom: 1rem;
    color: #ffffff;
}

.text h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
    color: #ffd700;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.text h3 {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    color: #ffd700;
}


.text ol {
    color: #ffffff;
    margin-bottom: 1rem;
    padding-left: 1.5rem;
}

.text li {
    margin-bottom: 0.5rem;
}

.navBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1f1a3a;
    padding: 0.5rem 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.burgerIcon {
    color: #f1f1f1;
    font-size: 1.5rem;
    cursor: pointer;
}

.navList {
    list-style: none;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #1f1a3a;
    transition: all 0.3s;
}

.show_burger_menu {
    display: flex;
    flex-direction: column;
}

.hide_burger_menu {
    display: none;
}

.navItem {
    color: #f1f1f1;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s;
}


.navItem:hover,
.active {
    color: #ffd700;
}

.adminTextField {
    background:  #f1f1f1;;
    color: #8b60ed;
}

@media (max-width: 767px) {

    .mascot {
        max-width: 150px;
        padding-left: 100px;
    }

    .textColumn {
        padding-left: 0.5rem;
    }

    .mascotColumn {
        padding-right: 0.5rem;
    }

    .quizText {
        z-index: 5;
        flex-grow: 1;
    }

    .quizText p {
        font-size: 1rem;
        line-height: 1.6;
        margin-bottom: 1rem;
        color: #ffffff;
    }

    .gameButton {
        font-size: 100%;
        padding: 0.5rem 1rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
        border: none;
        border-radius: 0.25rem;
        background-color: #8b60ed;
        color: #ffffff;
        cursor: pointer;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        transition: background-color 0.3s;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        width: 50%;
        height: 75%;
    }
}

/* Tablet devices (landscape) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .title {
        font-size: 2.5rem;
    }

    .instructions {
        font-size: 20px;
    }

    .mascot {
        max-width: 200px;
        padding-left: 150px;
    }
}

/* Tablet devices (portrait) */
@media only screen and (min-width: 600px) and (max-width: 767px) {
    .container {
        padding: 1rem;
    }

    .realmContainer {
        margin-bottom: 1rem;
    }

    .terms-and-conditions {
        padding: 1.5rem;
    }

    .title {
        font-size: 2.5rem;
    }

    .instructions {
        font-size: 20px;
    }

    .mascot {
        max-width: 150px;
        padding-left: 100px;
    }

    .textColumn {
        padding-left: 0.5rem;
    }

    .mascotColumn {
        padding-right: 0.5rem;
    }

}

